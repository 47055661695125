import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ScrollLink from './scroll-link';

const Tabs = ({
    tabs,
    activeTab,
}) => {
    return <nav className="navbar bg-white navbar-expand-sm navbar-light sticky-top">
        <div className="container">
            <div className="navbar-nav flex-row flex-wrap">
                {!tabs ? null : tabs.map((tab, index) => <div
                    key={index}
                    className="nav-item mx-3"
                >
                    <ScrollLink
                        to={tab.path}
                        className={classnames('nav-link shadow-none px-0', {
                            'active': tab.text === activeTab
                        })
                        }>
                        {tab.text}
                    </ScrollLink>
                </div>)}
            </div>
        </div>
    </nav>;
};

Tabs.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.Object),
    activeTab: PropTypes.string
};

Tabs.defaultProps = {
    tabs: [],
    activeTab: ''
};

export default Tabs;
