import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const Banner = (props) => {
    const { breadcrumb = [] } = props;
    return <div className="banner-component bg-primary position-relative">
        <div className="container">
            {breadcrumb.length > 0 && <nav>
                <ol className="breadcrumb bg-transparent text-light px-0">
                    {breadcrumb.map((breadcrumb, index) => <li key={index} className="breadcrumb-item">
                        <Link className="breadcrumb-link" activeClassName="breadcrumb-active-link" to={breadcrumb.path}>{breadcrumb.title}</Link>
                    </li>)}
                </ol>
            </nav>}
            <h1 className="title m-0 text-white py-5" dangerouslySetInnerHTML={{ __html: props.title || props.name }} />
            {props.children}
        </div>
    </div>;
};

Banner.propTypes = {
    breadcrumb: PropTypes.array,
    title: PropTypes.string,
    name: PropTypes.string,
    children: PropTypes.element
};

export default Banner;