import React, { useState, useMemo, useEffect } from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';
import Layout from '../components/layout';
import Banner from '../components/banner';
import Tabs from '../components/tabs';
import { ObjectUtils } from '../utils';
import classnames from 'classnames';
import { Footer } from '../components';
import LocalizationService from '../services/localization-service';
import { ResourceUtils } from '../utils';
import { GatsbyImage } from 'gatsby-plugin-image';
import { LANGUAGES, FULL_PATH_REGEX, FULL_PATH_LOCALE_PREFIXED_REGEX } from '../resources/constants';

const FIRST_MATCHING_GROUP = 1
const SECOND_MATCHING_GROUP = 2

const createMap = (prefix, resources) => {
    const prefixEscaped = prefix?.replace(/\./g, '\\.')
    const expression = new RegExp(`(${prefixEscaped}\.)(.*)`);
    const object = {}
    resources.forEach(({ key }) => {
        const property = key.match(expression)?.[SECOND_MATCHING_GROUP]
        if (property) {
            object[property] = property;
        }
    })
    return object
}

const SolutionsPageTemplate = ({ data, pageContext }) => {
    const { name, tabsResourceName } = pageContext;
    const { content, tabs } = data
    const { resources, images } = content
    const [currentPath, setCurrentPath] = useState()
    const hideImage = false;
    const titleSize = 'default'
    const localized = new LocalizationService(resources)
    const map = useMemo(() => createMap(name, resources), [resources])
    const { resources: tabResources } = tabs
    const tabsMap = useMemo(() => createMap(tabsResourceName, tabResources), [tabResources])
    const microcopy = ResourceUtils.getMicrocopy(localized, name, map)
    const structuredCopy = ObjectUtils.fromMap(microcopy);

    const localizedTabs = new LocalizationService(tabResources)
    const tabsMicrocopy = ResourceUtils.getMicrocopy(localizedTabs, tabsResourceName, tabsMap)
    const pageTabs = ObjectUtils.fromMap(tabsMicrocopy)?.tab?.map((tab => ({ ...tab, text: tab?.title })))

    const intl = useIntl();
    const { locale } = intl;
    const currentLanguage = LANGUAGES.find(language => language.locale == locale);
    const hasLocalePrefix = currentLanguage?.hasLocalePrefix;

    useEffect(() => {
        setCurrentPath(window.location.pathname.match(!hasLocalePrefix ? FULL_PATH_REGEX : FULL_PATH_LOCALE_PREFIXED_REGEX)?.[FIRST_MATCHING_GROUP])
    }, [])

    const renderDescription = (body) => {
        if (!Array.isArray(body)) {
            return <p>{body}</p>
        }
        else {
            return <p>{body.map(section => {
                if (!section?.list) {
                    return section;
                }
                else {
                    return <ul>{section?.list?.map(item => <li>{item}</li>)}</ul>
                }
            })}</p>
        }
    }

    const renderSection = ({ title, body }, index) => {
        const flip = index % 2 === 0;
        return <div className="image-content-row">
            <div className="row gx-5 pb-3">
                <div className={`col-lg-${hideImage ? '12' : '6'} col-md-${hideImage ? '12' : '6'} col-sm-12 mt-2 mt-md-0 order-2 order-md-${flip ? '1' : '3'}`}>
                    <h3 className={classnames("d-md-block title mt-3 mt-md-0", {
                        [titleSize]: titleSize !== 'default'
                    })}>{title}</h3>
                    {renderDescription(body)}
                </div>
                {!hideImage && <div className="col-md-6 col-sm-12 mt-1 order-1 order-md-2 pe-auto pe-md-5">
                    <GatsbyImage image={images[index]?.image?.gatsbyImageData} />
                </div>}
            </div>
        </div>;
    }

    return <Layout className="services">
        <Banner name={structuredCopy.title} />
        <div className='container'>
            <Tabs
                tabs={pageTabs}
                activeTab={pageTabs.find(tab => tab.path == currentPath)?.text}
            />
            <div className='container'>
                <div className='bg-white py-4 section-component padding-default'>
                    <div className='section-component'>
                        <div className="title text-center">
                            <h3 className="position-relative">
                                {structuredCopy.intro_section.title}
                            </h3>
                        </div>
                        <div className='text-center'>
                            <p>
                                {structuredCopy.intro_section.body}
                            </p>
                        </div>
                        <div className='mt-5'>
                            {structuredCopy.section.map((item, i) => renderSection(item, i))}
                        </div>
                        {structuredCopy.outro && <h3>
                            <b>
                                {structuredCopy.outro}
                            </b>
                        </h3>}
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </Layout>;
};

export default SolutionsPageTemplate;

export const query = graphql`
  query Page($name: String!, $tabsResourceName: String!, $languageRegEx: String!) {
    content: contentfulResourceSet(name: {eq: $name}, node_locale: {regex: $languageRegEx}) {
        name
        resources {
            key
            value {
            value
            }
            isHtml
        }
        images {
            image {
                gatsbyImageData
            }
        }
        node_locale
      }
      tabs: contentfulResourceSet(name: {eq: $tabsResourceName}, node_locale: {regex: $languageRegEx}) {
        name
        resources {
            key
            value {
            value
            }
            isHtml
        }
        node_locale
      }
  }
`;